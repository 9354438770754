const routes = {
  home: '/',
  aboutUs: '/about-us',
  basket: '/basket',
  order: '/order',
  products: {
    list: '/products',
    details: (id: string | null) => `/product/${id}`,
  },
  contact: '/contact',
};

export default routes;
