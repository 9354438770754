'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useContext, useEffect, useState } from 'react';

import Button from '@/components/Button';
import LocaleSwitcher from '@/components/LocaleSwitcher';
import Navigation from '@/components/Navigation';
import { MobileProps } from '@/components/Navigation/types';
import CartContext from '@/contexts/CartContext';
import cartIcon from 'public/icons/shopping_bag.svg';
import logo from 'public/images/UNITRA-logo.svg';

import styles from './styles.module.scss';

const Menu = ({ isMobile, lng }: MobileProps) => {
  const [qty, setQty] = useState(0);
  const { cart } = useContext(CartContext);

  useEffect(() => {
    if (cart) {
      const productsQts = cart.items.map(el => el.quantity);
      const sum = productsQts.reduce((a, b) => a + b, 0);
      setQty(sum);
    } else {
      setQty(0);
    }
  }, [cart]);

  return (
    <div className={styles.container}>
      <Link href={`/${lng}`} className={styles.logoContainer}>
        <div className={styles.logoWrapper}>
          <Image src={logo} alt="Logo UNITRA" width={172} height={40} />
        </div>
      </Link>
      <div className={styles.navigation}>
        <Navigation lng={lng} />
        {!isMobile && (
          <>
            <div className={styles.localeCartContainer}>
              <LocaleSwitcher />
              {lng === 'cs' || (
                <Button
                  className={styles.cartBtn}
                  variant="navIcons"
                  href={`/${lng}/basket`}
                  isNoArrow
                >
                  <>
                    {qty > 0 && <div className={styles.itemCounter}>{qty}</div>}
                    <Image
                      src={cartIcon}
                      alt="Cart icon"
                      height={26}
                      width={26}
                    />
                  </>
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Menu;
